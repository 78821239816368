<template>
  <svg
    width="132"
    height="32"
    viewBox="0 0 132 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8155 11.717L18.674 14.6463L24.5325 11.717V0H12.8155V11.717ZM0 22.0634L6.20908 32L16.1457 25.7909L16.1463 18.8823L9.93661 15.8543L0 22.0634ZM37.5587 22.0634L31.3496 32L21.413 25.7909L21.4123 18.8823L27.622 15.8543L37.5587 22.0634Z"
      fill="#83C6F2"
    />
    <path
      d="M73.4946 5.10385H69.5266L63.5106 15.1519L57.5266 5.10385H53.5586L61.6226 18.5439V27.5039H65.3986V18.5439L73.4946 5.10385Z"
      fill="white"
    />
    <path
      d="M87.4156 4.04785V11.8239C85.8796 10.5439 83.9276 9.80785 81.7196 9.80785C76.4716 9.80785 72.7276 13.6799 72.7276 18.8959C72.7276 24.0479 76.4716 27.9519 81.7196 27.9519C84.1196 27.9519 86.2636 27.0559 87.7996 25.5519L88.2796 27.5359H90.9676V4.04785H87.4156ZM81.9756 24.5599C78.7436 24.5599 76.3436 22.1279 76.3436 18.8639C76.3436 15.6319 78.7436 13.1999 81.9756 13.1999C85.2396 13.1999 87.6396 15.6319 87.6396 18.8639C87.6396 22.0959 85.2396 24.5599 81.9756 24.5599Z"
      fill="white"
    />
    <path
      d="M112.455 18.9279C112.455 13.9039 108.935 9.80785 103.623 9.80785C98.5668 9.80785 94.7588 13.7119 94.7588 18.8639C94.7588 24.0479 98.5668 27.9519 103.623 27.9519C107.495 27.9519 110.663 25.7119 112.039 22.0639H108.295C107.367 23.6639 105.703 24.6239 103.623 24.6239C100.807 24.6239 98.7908 22.8959 98.3428 20.0799H112.359C112.423 19.6959 112.455 19.3119 112.455 18.9279ZM103.623 13.0399C106.279 13.0399 108.231 14.5759 108.871 17.1999H98.4388C99.0148 14.6399 100.967 13.0399 103.623 13.0399Z"
      fill="white"
    />
    <path
      d="M127.534 10.2239L122.478 23.0559L117.294 10.2239H113.55L120.846 27.5039H124.078L131.182 10.2239H127.534Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoAlternate",
};
</script>